import Mock from 'mockjs'

import homeApi from './mockServeData/home'
import userApi from './mockServeData/user'

// 拦截的方式
// 知道接口请求的具体路径：
// 三个参数：接口路径，请求的类型，返回的数据
Mock.mock('/repository/getData',homeApi.getStatisticalData)
Mock.mock(/user\/add/,'post',userApi.createUser)
Mock.mock(/user\/edit/,'post',userApi.updataUser)

Mock.mock(/user\/getUser/,'get',userApi.getUserList)
Mock.mock(/user\/del/,'post',userApi.deleteUser)
