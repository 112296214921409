import Vue from "vue";
// 该文件专门用于创建整个应用的路由器
import VueRouter from "vue-router";
import el from "element-ui/src/locale/lang/el";

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
    return originalPush.call(this,location).catch(err => err)
}


Vue.use(VueRouter)
// import Home from '../views/Home.vue'（按需引入之后就不用写这个了）
// 引入组件
const routes = [
    {
        path: '/',
        name: 'Main',
        redirect: '/login',
        leaf:false,
        hidden:false,
        // 按需引入的写法：
        component: () => import('../views/Main.vue'),
        meta:{title:'后台管理系统'},
        children:[

            {
                path: '/repository',
                name: 'repository',
                hidden:false,
                component: () => import('../views/repository'),
                meta:{title:'知识库',breadNumber:1,toPath:'/repository'}
            },
            {
                path: '/troubleshooting',
                name: 'troubleshooting',
                hidden:true,
                component: () => import('../views/troubleshooting'),
                meta:{title:'故障诊断',breadNumber:1,toPath:'/troubleshooting'}
            }
        ] 
    },
    {path: '/login',
        name:'login',
        component:() => import('../views/Login/login.vue')
    },
]

// 创建一个路由器

const router = new VueRouter({
    // mode: 'history',
    base:'/vue-manage/',
    mode: 'hash',
    routes
})

//导航守卫
//使用router.beforeEach 注册一个全局前置守卫，判断用户是否登录
router.beforeEach((to,from,next)=>{
//    如果去往登录页面则放行
    if(to.path === '/login'){
        next()
    }else {
    //    从本地存储里获取token
        let token = localStorage.getItem('token')
    //    判断token是否为空，为空则跳转到登录页，有token则放行
        if(token === null || token === ''){
            next({path:'/login'})
        }else{
            next()
        }
    }
})


export default router