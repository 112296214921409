import Vue from "vue";
import Vuex from 'vuex'
// 定义模块
import tab from './tab'

Vue.use(Vuex)
// 以模块的方式来定义store

export default new Vuex.Store({
    modules:{
        tab

    }

})