// 这个文件用于存放用户管理模拟的数据：
import Mock from "mockjs";

// get请求从config.url获取参数，post从config.body中获取参数
function param20bj(url) {
    const search = url.split('?')[1]
    // console.log("路径->>"+url)
    // console.log("路径参数->>"+search)
    if (!search) {
        return {}
    } else {
        let res = JSON.parse(
            '{"' +
            decodeURIComponent(search)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
            '"}'
        )
        return res
    }


}

let List = []
const count = 200

for (let i = 0; i < count; i++) {
    List.push(
        Mock.mock({
            id: Mock.Random.guid(),
            name: Mock.Random.cname(),
            isOpen: Mock.mock('@county(true)'),
            'age|18-60': 1,
            date: Mock.Random.date(),
            type: Mock.Random.integer(0, 1, 2)
        })
    )
}

export default {
    /**
     * 获取列表
     * 要带参数 name, page, limit; name可以不填，page,limit有默认值
     * @param name, page, limit
     * @return{{code:number, count:number,data:*[]}}
     */
    getUserList: config => {
        const {name, page = 1, limit = 10} = param20bj(config.url)
        console.log('name:' + name, 'page:' + page, '分页大小limit:' + limit)
        const mockList = List.filter(user => {
            if (name && user.name.indexOf(name) === -1 && user.addr.indexOf(name) === -1) return false
            return true
        })
        const pageList = mockList.filter((item, index) => index < limit * page && index >= limit * (page - 1))
        return {
            code: 20000,
            count: mockList.length,
            list: pageList
        }
    },

    /**
     * 添加用户
     * @param name, addr, age,birth,sex
     * @return{{code:Number, data:{message:string}}}
     */
    createUser: config => {
        const {name, type, date, isOpen} = JSON.parse(config.body)
        List.unshift({
            id: Mock.Random.guid(),
            name: name,
            type: type,
            date: date,
            isOpen: isOpen,

        })
        return {
            code: 20000,
            data: {
                message: '添加成功'
            }
        }
    },

    /**
     * 删除用户
     * @param id
     * @return {*}
     }
     */

    deleteUser: config => {
        // /user/del/?id=xxx  截取问号后面的
        const param = config.url.split("?")[1];
        // param = id=xx  substring用于截取id=后面的值
        const id = param.substring(2, param.length)
        // console.log("xx要删除的id为" + id)
        if (!id) {
            return {
                code: -999,
                message: '参数不正确'
            }
        } else {
            List = List.filter(u => u.id !== id)
            return {
                code: 20000,
                message: '删除成功'
            }
        }
    },

    /**
     *修改用户
     * @param id,name,addr,age,birth,sex
     * @return{{code:Number, data:{message:string}}}
     */

    updataUser: config => {
        const {id, name, isOpen, date, type} = JSON.parse(config.body)
        const type_num = parseInt(type)
        List.some(u => {
            if (u.id === id) {
                u.name = name
                u.isOpen = isOpen
                u.date = date
                u.type = type_num
                return true
            }
        })
        return {
            code: 20000,
            data: {
                message: '编辑成功'
            }
        }

    }

}