import Vue from 'vue'
import App from './App.vue'
// import axios from "axios";
import './assets/less/index.less'
// 引入VueRouter
import router from '../router'
import store from '../src/store'
import http from 'axios'
// 引入mockjs
import '../src/api/mock.js'

// 按需引入
import {
    Button, Radio, Container, Header, Main, Aside,
    Menu, Submenu, MenuItem, MenuItemGroup,
    Dropdown, DropdownItem, DropdownMenu,
    Form, FormItem, Input, Switch, DatePicker, Select, Option,
    Dialog, Table, TableColumn, Pagination, MessageBox, Message, Upload,Col,Breadcrumb,BreadcrumbItem,
    Tooltip

} from 'element-ui'
import axios from "axios";
// Vue.use(http)
Vue.config.productionTip = false
Vue.use(router)
// Vue.prototype.$http = http
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message
Vue.prototype.axios = http;

// 应用ElementUI
Vue.use(Button)
Vue.use(Radio)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Aside)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Switch)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Upload)
Vue.use(Col)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Tooltip)
axios.defaults.withCredentials=true;
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app') 
