import current from "element-ui/packages/table/src/store/current";
export default{
    // 定义状态默认为展开
    state:{
        isCollapse:false,
        tabList:[
            {
                path:'/repository',
                name:'home',
                level:0,
                label:'通用后台管理系统',
                icon:'home'
            },
        ],
        currentMenu:null
    },
    // 定义改变state的方法：mutations接收的两个参数分别是：state，所需要传入的参数
    mutations:{
        collapseMenu(state){
            state.isCollapse = !state.isCollapse
        },
        selectMenu(state,val){
            // console.log('route.matched',this)
            // console.log('val',val)
            // console.log('val.name',val.name)
            if(val.name !== 'home') {
                state.currentMenu = val

                // console.log('currentMenu',state.currentMenu)
                const result = state.tabList.findIndex(item => item.name === val.name)
                // console.log(result)
                if (result === -1 ) {
                    state.tabList.push(val)
                } else {
                    state.currentMenu = null
                }
            }
        },
    }
}